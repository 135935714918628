@media (max-width: 992px) {
  .mob_sty_nav {
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;
    /* border-top: 1px solid #dfdfdf; */
    margin-top: 10px;
    height: 65px;
  }
}
