/* =====================mediaquery========================= */
@media (max-width: 600px) {
  .logosty {
    margin: 5px !important;
  }
  .btn_mob {
    padding-bottom: 20px;
  }
  .line_dots {
    bottom: 16px;
    margin-left: 23px;
  }
  .nav-link {
    padding: 0px !important;
  }
  .line_dots_name {
    bottom: 35px;
    margin-left: 6px;
    font-size: 10px;
  }
  .line_dots_name_empty {
    margin-left: 40px !important;

    font-size: 12px;
  }
  .line_dots_empty {
    bottom: 27px;
    margin-left: 45px;
  }

  .form_sty {
    margin-right: 0px !important;
    flex-flow: unset !important;
  }
  .notice_dot {
    width: 94px;
    height: unset;

    background: #413f3f;
    box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.06);
    border-radius: 4px;
    color: #fff;
  }
  .notic_par_sty {
    font-size: 9px;
  }
  .margin_sty_notic {
    margin-left: 350px;
  }
  .margin_sty_notic {
    margin-left: unset;
  }

  .active .line_dots {
    bottom: 16px;
  }
  .mobsty {
    margin-left: 0px !important;
  }
  .notice_dot {
    width: auto !important;
  }
  .mob_nav_sty .nav-link {
    padding: unset !important;
  }
}
