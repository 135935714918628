* {
  box-sizing: border-box;
  font-family: "Montserrat", sans-serif;
}
body {
  font-family: "Montserrat", sans-serif !important;
}

.logosty {
  font-family: "Poppins", sans-serif !important;
  color: #000000 !important;
  font-size: 33px !important;
  font-weight: 700 !important;
}
.Login_btn {
  background-color: #333333 !important;
  font-family: "Nunito", sans-serif !important;
  font-weight: bold !important;
  padding: 9px 25px !important;
  border: none !important;
  color: #fff !important;
  text-decoration: none;
}
.Login_btn:focus {
  box-shadow: none !important;
}
.dropdown_btn {
  color: #000000 !important;
  background: none !important;
  font-size: 16px !important;
  font-weight: 700 !important;
  font-family: "Nunito", sans-serif !important;
  border: none !important;
  outline: none !important;
  margin: 10px;
}
.dropdown_btn:focus {
  box-shadow: none !important;
}
.searchIcon {
  padding: 10px;
}
.search_sty {
  margin: 10px;
  outline: none !important;
  color: #333333 !important;
  cursor: pointer !important;
}
.search_sty:hover {
  box-shadow: none !important;
  border: none !important;
}
.form_sty {
  /* margin-right: 110px !important; */
  flex-flow: unset !important;
}
.para_hedding {
  font-size: 26px;
  font-weight: bold !important;
  font-family: "Nunito", sans-serif;
  font-style: normal;
  color: #333333;
}
.card_sty {
  border: none !important;
  background: #f9f9f9 !important;
  box-shadow: 2px 8px 20px rgba(0, 0, 0, 0.1) !important;
  border-radius: 8px !important;
  padding: 10px !important;
}
.theme_btn {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  background: #333333;
  border-radius: 5px !important;
  outline: none !important;
  text-transform: uppercase;
  padding: 10px 20px;
  color: #ffffff;
  border: none;
}
.theme_btn:hover {
  color: #fafafa !important;
  text-decoration: none !important;
}
.date_line {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 24px;
  color: #828282;
}
.main_para {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: #525252;
}
.para_hedding_newscontent {
  font-size: 18px;
  font-weight: 700 !important;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  color: #4f4f4f;
  margin: 20px;
}
.dots_headding {
  width: 10px;
  height: 10px;
  margin: 8px;
  border-radius: 50%;
  background: #000;
}
.font_norma {
  font-weight: normal;
}
.dots_gary_headding {
  width: 10px;
  height: 10px;
  margin: 8px;
  border-radius: 50%;
  background: #908b5d;
}
.manage_shear_icon {
  display: flex;
  justify-content: space-between;
}
.dots_red_headding {
  width: 10px;
  height: 10px;
  margin: 8px;
  border-radius: 50%;
  background: #9c55ae;
}
.dots_green_headding {
  width: 10px;
  height: 10px;
  margin: 8px;
  border-radius: 50%;
  background: #55ae5e;
}
.dots_darkred_headding {
  width: 10px;
  height: 10px;
  margin: 8px;
  border-radius: 50%;
  background: #ae5555;
}
.line_sty {
  max-width: 100%;
  height: 2px;
  background: #7c7777;
}
.line_dots {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: #bdbdbd;
  position: relative;
  bottom: 29px;
  margin-left: 50px;
}
.active .line_dots {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: #fff;
  border: 1px solid !important;
  position: relative;
  bottom: 29px;
}
a {
  color: #413f3f !important;
  text-decoration: none;
  background-color: transparent;
}

.line_dots_empty {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: #fff;
  border: 1px solid;
  position: relative;
  bottom: 33px;
  margin-left: 150px;
}
.line_dots_name {
  font-family: "Montserrat", sans-serif;
  position: relative;
  bottom: 40px;
  margin-left: 35px;
  font-size: 13px;
}
.line_dots_name_empty {
  position: relative;
  bottom: 35px;
  margin-left: 40px;
  margin-left: 136px;
  font-family: "Montserrat", sans-serif;
}
.input_search_sty {
  border: none !important;
  padding: 10px;
  width: 145px;
}
.input_search_sty:focus-visible {
  border: none !important;
}
#demo-2 input[type="search"] {
  padding-left: 10px;
  color: transparent;
  cursor: pointer;
}
#demo-2 input[type="search"]:hover {
  background-color: transparent;
}
#demo-2 input[type="search"]:focus {
  width: 130px;
  padding-left: 32px;
  color: #000;
  background-color: #fff;
  cursor: auto;
}
#demo-2 input:-moz-placeholder {
  color: transparent;
}
#demo-2 input::-webkit-input-placeholder {
  color: transparent;
}
.select_sty {
  -webkit-appearance: none;
  appearance: none;
  background: url(http://cdn1.iconfinder.com/data/icons/cc_mono_icon_set/blacks/16x16/br_down.png)
    no-repeat calc(100% - 10px) !important;
  padding-right: 35px;
  border: none;
  /* background: transparent !important; */
  font-family: "Nunito";
  font-weight: 700;
}
.select_sty:focus {
  outline: none !important;
}
.social_icons {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  position: absolute;
}
.card-body {
  padding: 20px !important;
  padding-right: 17px !important;
  margin-right: 20px;
}
.margintop_10px {
  margin-top: 10px;
}
.margintop_icon {
  margin-right: -14px;
  margin-top: 20px;
}
.inputsty .form-control {
  font-size: 13px;
  border: none !important;
  border-bottom: 1px solid #e4e4e4 !important;
}
.inputsty.form-control:focus {
  background-color: none !important;
}
.inputsty {
  padding: 5px;
}
.modaladduser {
  padding: 10px;
  border-radius: 10px;
}
.btnsty {
  background: rgb(43, 179, 88);
  padding: 10px 10px;
  color: #fff !important;
  border-radius: 5px;
  outline: none !important;
  text-decoration-line: none !important;
}
.Login_btn:hover {
  color: #fff;
  text-decoration: none !important;
}
.inputsty .form-control:focus {
  box-shadow: none;
}
.margin_sty {
  margin-left: 60px;
}
.margin_line {
  margin-left: 40px;
}
.margin_sty_four_dots {
  margin-left: 60px;
}
/* .margin_sty_four_dots_name{
  margin-left: 181px;
} */
.margin_fourteen_dot {
  margin-left: 330px;
}
.margin_fourteen_line {
  margin-left: 318px;
}
.notice_box {
  width: 200px;
  height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.option_sty {
  font-family: "Nunito";
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
  cursor: pointer;
  /* background: #f8f9fa !important; */
  /* padding: 15px 15px; */

  /* identical to box height */

  color: #000000 !important;
}
.new_input_sty {
  border: none !important;
  outline: none !important;
  background: #f7f7f7;
  border-radius: 4px;
}
.notice_dot {
  width: 376px;
  left: 0px;
  top: 62px;
  background: #413f3f;
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.06);
  border-radius: 4px;
  color: #fff;
}
.notic_par_sty {
  font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 1.7;
    padding: 15px;
}
.margin_sty_notic {
  margin-left: 140px;
}
.line_notic_name_sty {
  position: relative !important;
  bottom: 35px !important;
  margin-left: 292px !important;
}

.line_notic_dot_sty {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: #fff;
  border: 1px solid;
  position: relative;
  bottom: 33px;
  margin-left: 313px;
}
.link_sty {
  color: #000000 !important;
  font-family: " Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
}
.sty_search_icon {
  margin-right: 22px;
  position: absolute;
  right: 370px;
  top: 30px;
}
.Active_Dot {
  width: 12px;
  height: 12px;
  border: 1px solid #000;
  background: #fff;
  border-radius: 50%;
}
.select option {
  display: flex;
  margin: 0 auto;
  width: 100%;
  max-width: 500px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-flex: 1;
}
.right_card_sty_first {
  padding-bottom: 162px;
}
.right_card_sty_second {
  padding-bottom: 178px;
}
.right_card_sty_therd {
  padding-bottom: 117px;
}
.showmore_sty {
  cursor: pointer;
}
